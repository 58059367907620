/**
 * Add any polyfills needed based on support below.
 * Do not include polyfills from core-js. They are added through BrowserList & SWC.
 *
 * At the time of writing, we support the following browser versions.
 * Safari 10
 * Chrome 60
 * Edge 80
 * Samsung Internet 13
 * Firefox 78
 * Opera 84
 * Android Webview 99
 */

import { logException, logMessage } from "@wss/error-tracking";

const imports: Promise<unknown>[] = [];

function handleError(message: string): (error: unknown) => void {
  return function onError(error): void {
    throw new Error(`Failed to load "${message}" module`, {
      cause: error,
    });
  };
}

function inputTypeDateSupported() {
  const input = document.createElement("input");
  const value = "a";
  input.setAttribute("type", "date");
  input.setAttribute("value", value);
  return input.value !== value;
}

function isFunctionAConstructor(Fn: { new (): unknown }): boolean {
  try {
    new Fn();
    return true;
  } catch (error) {
    return false;
  }
}

if (!HTMLCanvasElement.prototype.toBlob) {
  imports.push(
    import(/* webpackMode: "eager" */ "blueimp-canvas-to-blob").catch(
      handleError("blueimp-canvas-to-blob")
    )
  );
}

if (!Element.prototype.append) {
  imports.push(
    import(
      /* webpackMode: "eager" */ "mdn-polyfills/Node.prototype.append"
    ).catch(handleError("mdn-polyfills/Node.prototype.append"))
  );
}

if (!Element.prototype.prepend) {
  imports.push(
    import(/* webpackMode: "eager" */ "parent-node-prepend-polyfill").catch(
      handleError("parent-node-prepend-polyfill")
    )
  );
}

if (!window.IntersectionObserver) {
  imports.push(
    import(/* webpackMode: "eager" */ "intersection-observer").catch(
      handleError("intersection-observer")
    )
  );
}

if (!FormData.prototype.delete) {
  imports.push(
    import(/* webpackMode: "eager" */ "formdata-polyfill").catch(
      handleError("formdata-polyfill")
    )
  );
}

/** @see {@link https://caniuse.com/input-datetime Can I Use} */
if (!inputTypeDateSupported()) {
  imports.push(
    import(/* webpackMode: "eager" */ "configurable-date-input-polyfill").catch(
      handleError("configurable-date-input-polyfill")
    )
  );
}

/**
 * @see {@link https://github.com/zloirock/core-js/issues/1039 No CoreJS Support}
 * @see {@link https://caniuse.com/mdn-api_eventtarget_eventtarget Can I Use}
 */
if (!isFunctionAConstructor(EventTarget)) {
  imports.push(
    import(/* webpackMode: "eager" */ "event-target-polyfill").catch(
      handleError("event-target-polyfill")
    )
  );
}

/** @see {@link https://caniuse.com/mdn-javascript_builtins_globalthis Can I Use} */
if (typeof globalThis === "undefined") {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  (window.globalThis as unknown) = window;
}

/** @see {@link https://caniuse.com/mdn-javascript_builtins_promise_allsettled All Settled Can I Use} */
if (!Promise.allSettled) {
  Promise.allSettled = function polyfillPromiseAllSettled(
    promises: Promise<PromiseSettledResult<unknown>>[]
  ) {
    const proms = promises.map((prom) =>
      prom
        .then((value) => ({
          status: "fulfilled" as const,
          value,
        }))
        .catch((reason) => ({
          status: "rejected" as const,
          reason,
        }))
    );
    return Promise.all(proms);
  };
}

if (imports.length) {
  logMessage("Uses has a device that needed the polyfills", "Info");
}

// eslint-disable-next-line import/prefer-default-export
export const polyfills = Promise.allSettled(imports).then((results) => {
  for (const result of results) {
    if (result.status === "rejected") {
      logException(result.reason);
    }
  }
  return results;
});
