import { EcommerceData } from "./AddToCart/addToCartHelpers";

type TriggerEvent = {
  event: string;
};

type CustomEvent = {
  event: string;
  eventLabel?: string;
  eventAction?: string;
  eventCategory?: string;
  eventName?: string;
};

type EcommerceEvent = {
  event: string;
  ecommerce: EcommerceData | Record<string, unknown>;
  firstTimePurchaser?: string;
};

type DataLayerVariable = {
  [key: string]: string | number | null;
};

type GtmEvent = TriggerEvent | CustomEvent | EcommerceEvent | DataLayerVariable;

const sendGtmEvent = (gtmEvent: GtmEvent) => {
  // A follow-up ticket will be created to remove the 'any' cast and linked to PBI #866979 in Webstaurantstore.com\SEM
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
  window.dataLayer.push(gtmEvent as any);
};

const clearEcommerceInDataLayer = () => {
  window.dataLayer.push({ ecommerce: null });
};

const clearGa4EventInDataLayer = () => {
  window.dataLayer.push({
    event: null,
    eventAction: null,
    eventName: null,
    eventCategory: null,
    eventLabel: null,
  });
};

const vendorDimension = "dimension39";

const itemNumberDimension = "dimension40";

const carouselIdDimension = "dimension52";

const productReviewCountDimension = "dimension64";

const analyticsStorageKey = "analyticsData";

export {
  GtmEvent,
  sendGtmEvent,
  vendorDimension,
  itemNumberDimension,
  carouselIdDimension,
  productReviewCountDimension,
  analyticsStorageKey,
  clearEcommerceInDataLayer,
  clearGa4EventInDataLayer,
};
