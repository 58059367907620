/* eslint-disable import/prefer-default-export */
import { checkIfCarouselIsRecAi, createProducts } from "~/userAnalytics";
import { sendGtmEvent } from "../definitions";
import { EcommerceData, formatEcommerceData } from "./addToCartHelpers";

export const pushCarouselAddToCartEventToDataLayer = (
  carouselName: string,
  productAddedToCartFromCarousel: EcommerceData
) => {
  sendGtmEvent({
    event: "carouselAddToCartClick",
    carouselName,
    carouselModel: checkIfCarouselIsRecAi(carouselName),
    ecommerce: {
      ...productAddedToCartFromCarousel,
    },
  });
};

const getCarouselNameFromAddToCartButton = (
  addToCartButtonOnCarousel: HTMLElement
) => {
  return addToCartButtonOnCarousel
    ?.closest("[gtm-carousel-name]")
    ?.getAttribute("gtm-carousel-name");
};

const getProductItemNumberFromAddToCartButtonInGenericCarousel = (
  addToCartButtonOnCarousel: HTMLElement
) => {
  return addToCartButtonOnCarousel.parentElement
    ?.getElementsByClassName("item_number")[0]
    ?.getAttribute("value");
};

const getProductItemNumberFromAddToCartButtonInTopProductsCarousel = (
  addToCartButtonOnCarousel: HTMLElement
) => {
  return addToCartButtonOnCarousel?.parentElement?.parentElement
    ?.getElementsByClassName("item_number")[0]
    ?.getAttribute("value");
};

const getProductQuantityAddedToCartFromCarousel = (
  addToCartButtonOnCarousel: HTMLElement
) => {
  return addToCartButtonOnCarousel.parentElement
    ?.getElementsByClassName("quantity")[0]
    ?.getAttribute("value");
};

const getAddToCartButtonsFromCarouselByNameAttribute = (
  carousel: Element
): NodeListOf<HTMLElement> => {
  return carousel?.querySelectorAll("[name=addToCartButton]");
};

const getAddToCartButtonsFromCarousel = (
  carousel: Element
): NodeListOf<HTMLElement> => {
  const addToCartButtonsUsingNameAttribute: NodeListOf<HTMLElement> = getAddToCartButtonsFromCarouselByNameAttribute(
    carousel
  );

  if (addToCartButtonsUsingNameAttribute.length > 0) {
    return addToCartButtonsUsingNameAttribute;
  }

  const addToCartButtonsUsingDataAttribute: NodeListOf<HTMLElement> = getAddToCartButtonsFromCarouselByDataAttribute(
    carousel
  );

  return addToCartButtonsUsingDataAttribute;
};

const getAddToCartButtonsFromCarouselByDataAttribute = (
  carousel: Element
): NodeListOf<HTMLElement> => {
  return carousel?.querySelectorAll("[data-action=addToCart]");
};

const quantityAddedToCartIsValid = (quantityAddedToCart: number) => {
  const maxAmountOfItemsAllowedByGoogleAnalytics = 30_000;
  return (
    quantityAddedToCart &&
    Number.isInteger(quantityAddedToCart) &&
    quantityAddedToCart <= maxAmountOfItemsAllowedByGoogleAnalytics
  );
};

export const bindAddToCartButtonClicksForCarouselTracking = () => {
  const gtmCarousels = document?.querySelectorAll("[gtm-carousel-name]");

  for (const carousel of gtmCarousels) {
    const addToCartCarouselButtons: NodeListOf<HTMLElement> = getAddToCartButtonsFromCarousel(
      carousel
    );

    for (const addToCartButton of addToCartCarouselButtons) {
      addToCartButton.addEventListener("click", async () => {
        const carouselName = getCarouselNameFromAddToCartButton(
          addToCartButton
        );

        const itemNumber =
          getProductItemNumberFromAddToCartButtonInGenericCarousel(
            addToCartButton
          ) ??
          getProductItemNumberFromAddToCartButtonInTopProductsCarousel(
            addToCartButton
          );

        const addToCartButtonCarouselPosition =
          [...addToCartCarouselButtons].indexOf(addToCartButton) + 1;

        const quantityAddedToCart = Number(
          getProductQuantityAddedToCartFromCarousel(addToCartButton)
        );

        if (
          quantityAddedToCartIsValid(quantityAddedToCart) &&
          carouselName &&
          addToCartButtonCarouselPosition &&
          itemNumber
        ) {
          const products = await createProducts([
            { itemNumber, quantity: quantityAddedToCart },
          ]);
          if (products[0]) {
            const formattedEcommerceData = formatEcommerceData(products, {
              position: addToCartButtonCarouselPosition,
              total: quantityAddedToCart * Number(products[0].price),
            });
            pushCarouselAddToCartEventToDataLayer(
              carouselName,
              formattedEcommerceData
            );
          }
        }
      });
    }
  }
};
