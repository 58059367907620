import React from "react";
import FastAnchor from "~/common/FastAnchor";

interface CategoryItemProps {
  link: string;
  displayName: string;
  mouseEnterMenuItem(displayName: string): void;
  isOpenFlyout: boolean;
}

function CategoryItem({
  link,
  displayName,
  isOpenFlyout,
  mouseEnterMenuItem,
}: CategoryItemProps) {
  const ariaLabel = displayName.replace(/<br ?\/?>/g, " ");

  return (
    <FastAnchor
      data-testid={`category-item-anchor-${displayName}`}
      href={link}
      className="cursor-pointer
          flex
          items-center
          justify-evenly
          justify-items-center
          py-2
          top-0
          text-center
          text-white
          no-underline
          antialiased
          border-none
          flex-auto
          font-semibold
          shadow-none
          leading-4
          whitespace-nowrap
          category-item
          hover:bg-green-800
          hover:rounded"
      data-type={displayName}
      aria-haspopup
      aria-label={ariaLabel}
      aria-expanded={isOpenFlyout}
      onMouseEnter={() => mouseEnterMenuItem(displayName)}
    >
      {displayName}
    </FastAnchor>
  );
}

export default CategoryItem;
