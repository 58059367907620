import {
  createProducts,
  Item,
  updateUserWithNewCartTotal,
} from "~/userAnalytics";
import { formatEcommerceData, EcommerceData } from "./addToCartHelpers";
import { sendGtmEvent, clearEcommerceInDataLayer } from "../definitions";
import {
  sendFacebookPixelAddToCartEvents,
  sendPinterestAddToCartEvents,
} from "./addToCartApiHelpers";

export const addToCart = async (items: Item[]) => {
  let total = 0;
  const feedIdentifiers: string[] = [];

  const products = await createProducts(items);
  if (products.length === 0) {
    return;
  }
  for (const product of products) {
    total += Number(product?.price ?? 0) * Number(product?.quantity ?? 0);
    if (product.feedIdentifier) {
      feedIdentifiers.push(product.feedIdentifier);
    }
  }
  // Done to avoid any floating point errors
  total = parseFloat(total.toFixed(2));
  // Note that prices here are only using P1, as the actual price can vary depending on user to user, so may vary slightly from the actual value.
  const newCartTotal = await updateUserWithNewCartTotal(true, total);
  if (newCartTotal.isError) {
    return;
  }
  sendPinterestAddToCartEvents(products);
  sendFacebookPixelAddToCartEvents(feedIdentifiers, total.toString());
  const formattedEcommerceProducts = formatEcommerceData(products, {
    total,
  });

  sendGtmAddToCartEvents(formattedEcommerceProducts, newCartTotal.value);
};

export const productRemoveFromCart = async (items: Item[]) => {
  const itemTotalCosts = items.map(
    (item) => (item?.price ?? 0) * (item?.quantity ?? 0)
  );
  const totalCost = itemTotalCosts.reduce((acc, curr) => acc + curr, 0);
  const newCartTotal = await updateUserWithNewCartTotal(false, totalCost);
  if (newCartTotal.isError) {
    return;
  }
  const products = await createProducts(items);
  if (products.length === 0) {
    return;
  }
  const formattedEcommerceProduct = formatEcommerceData(products, {
    total: totalCost,
  });
  clearEcommerceInDataLayer();
  sendGtmEvent({
    event: "productRemoveFromCart",
    cart_total: newCartTotal.value,
    ecommerce: formattedEcommerceProduct,
  });
};

const sendGtmAddToCartEvents = (
  ecommerce: EcommerceData,
  cartTotal: number
) => {
  clearEcommerceInDataLayer();
  sendGtmEvent({
    event: "addToCart",
    cart_total: cartTotal,
    ecommerce: { ...ecommerce },
  });
};
