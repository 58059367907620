import { getRandomNumberString, Product } from "~/userAnalytics";
import {
  getFacebookPixelId,
  getFacebookPixelUserData,
  sendFacebookPixelServerEvent,
} from "../apiHelpers";
import PinterestApiCommerceEvent from "../MarketingEvents/Pinterest/PinterestApiCommerceEvent";
import PinterestTagAddToCartEvent from "../TrackingPixels/TagEvents/PinterestTagAddToCartEvent";
import MarketingEventHelpers from "../MarketingHelpers/MarketingEventHelpers";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const fbq: any;

export const sendPinterestAddToCartEvents = (products: Product[]) => {
  new PinterestApiCommerceEvent(
    "add_to_cart",
    new PinterestTagAddToCartEvent(products),
    products
  ).sendEvent();
};

export const sendFacebookPixelAddToCartEvents = async (
  feedIdentifiers: string[],
  price: string
) => {
  const eventId = getRandomNumberString();
  const priceOfItemAddedToCart = MarketingEventHelpers.createItemPriceWithTrailingZero(
    parseFloat(price)
  );
  if (typeof fbq === "function") {
    fbq("trackSingle", getFacebookPixelId(), "AddToCart", {
      content_ids: feedIdentifiers,
      value: priceOfItemAddedToCart,
      currency: "USD",
      content_type: "product",
      eventID: eventId,
    });

    sendFacebookPixelServerEvent(
      "AddToCart",
      eventId,
      getFacebookPixelUserData(),
      {
        content_ids: feedIdentifiers,
        value: priceOfItemAddedToCart,
        currency: "USD",
        content_type: "product",
      }
    );
  }
};
