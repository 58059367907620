/* eslint-disable camelcase */
import { storageFactory } from "storage-factory";
import SHA256 from "crypto-js/sha256";
import isEqual from "lodash/isEqual";
import { MouseEvent } from "react";
import { Throwable } from "@wss/error-tracking/throwable";
import { toError } from "@wss/error-tracking/utils";
import { getDynamicData } from "./common/Store/actions/updateUserAndChatFromOrigin";
import { updateUser } from "./common/Store/actions/user";
import Store from "./common/Store";
import {
  GtmEvent,
  sendGtmEvent,
  carouselIdDimension,
  itemNumberDimension,
  vendorDimension,
  productReviewCountDimension,
  analyticsStorageKey,
  clearEcommerceInDataLayer,
  clearGa4EventInDataLayer,
} from "./analytics/definitions";
import { AnalyticsData } from "./common/Store/models/User";
import { UserAbFeatureOption } from "./common/AbFeature";
import { formatEcommerceData } from "./analytics/AddToCart/addToCartHelpers";

const session = storageFactory(() => window.sessionStorage);

export interface Item {
  itemNumber: string;
  quantity?: number;
  price?: number;
  feedIdentifier?: string | number;
}

export interface Product {
  name: string;
  id: string;
  price: string;
  brand: string;
  category: string;
  itemNumber?: string;
  quantity?: string;
  dimension39?: string;
  dimension40?: string;
  dimension52?: string;
  dimension64?: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_list_name?: string;
  position?: number;
  categoryId: number;
  feedIdentifier?: string;
}

interface InteractionLookup {
  [itemNumber: string]: string;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Window {
    dataLayer: GtmEvent[];
    google_tag_manager: unknown;
    /* eslint-enable camelcase */
  }
}

window.dataLayer = window.dataLayer || [];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const fbq: any;

type Result = {
  description: string;
  feedIdentifier: string;
  price: number;
  brand: string;
  category: string;
  itemNumber: string;
  vendorCode: string;
  productReviewCount: number;
  categoryId: number;
};

export const getProductData = async (
  itemNumbers: string
): Promise<Throwable<Result[]>> => {
  const result = await getDynamicData;
  if (result.isError) return result;
  const formattedItemNumbers = encodeURIComponent(itemNumbers);
  const endpointUrl =
    "/marketing:analytics/getproductdataforanalytics/?itemNumbers=";
  const getProductDataUrl = endpointUrl.concat(formattedItemNumbers);
  try {
    const response = await fetch(getProductDataUrl, { method: "GET" });
    if (!response.ok) {
      return {
        isError: true,
        error: new Error(
          "Failed to fetch product data from marketing analytics",
          { cause: response.statusText }
        ),
      };
    }
    const products: Result[] = await response.json();
    return { isError: false, value: products };
  } catch (e) {
    return { isError: true, error: toError(e) };
  }
};

export const getCarouselInteraction = (): InteractionLookup => {
  const sessionValue = session.getItem("carousel") || "{}";
  return JSON.parse(sessionValue);
};

export const createProducts = async (items: Item[]) => {
  const products: Product[] = [];
  const itemNumbers = items.map((item: Item) => item.itemNumber);
  if (!itemNumbers) {
    return [];
  }

  const data = await getProductData(itemNumbers.toString());
  if (data.isError) {
    return [];
  }
  data.value.forEach((result: Result, index: number) => {
    const productLookup = items.find(
      (item) =>
        item.itemNumber.trim().toUpperCase() ===
        result.itemNumber.trim().toUpperCase()
    );

    const carouselLookup = getCarouselInteraction();
    const carouselId =
      carouselLookup[result.itemNumber.trim().toUpperCase()] || "";

    const categories = result.category.split("/");

    const product: Product = {
      name: result.description,
      id: result.feedIdentifier,
      price: result.price.toString(),
      brand: result.brand,
      category: result.category,
      itemNumber: result.itemNumber,
      [vendorDimension]: result.vendorCode,
      [itemNumberDimension]: result.itemNumber.toUpperCase().trim(),
      [carouselIdDimension]: carouselId,
      [productReviewCountDimension]: result.productReviewCount.toString(),
      item_category: categories[0],
      item_category2: categories[1],
      item_category3: categories[2],
      item_category4: categories[3],
      item_category5: categories[4],
      position: index,
      categoryId: result.categoryId,
      feedIdentifier: result.feedIdentifier,
    };

    if (productLookup && productLookup.quantity) {
      product.quantity = productLookup.quantity.toString();
    } else {
      product.quantity = "0";
    }

    products.push(product);
  });

  return products;
};

export const trackClickThroughStats = (event: MouseEvent) => {
  if (event.currentTarget instanceof HTMLElement) {
    const body = new FormData();

    const { ctt, ctd } = event.currentTarget.dataset;

    if (ctt) body.append("ctt", ctt);
    if (ctd) body.append("ctd", ctd);

    fetch("/marketing:analytics/trackclickthroughstats/", {
      method: "POST",
      body,
      keepalive: true,
    });
  }
};

const carouselView = (
  carouselAnchor: HTMLElement,
  observer: IntersectionObserver,
  eventName: string
) => {
  if (eventName === "recAiEvent") {
    const { placementId, category } = carouselAnchor.dataset;
    const { id } = carouselAnchor;
    if (placementId || id) {
      sendGtmEvent({
        event: eventName,
        eventCategory: category || "RecAi",
        eventLabel: placementId || id,
        eventAction: "Carousel Impression",
      });
    }
    observer.disconnect();
  } else {
    sendGtmEvent({
      event: eventName,
      eventAction: "Carousel Impression",
    });
    observer.disconnect();
  }
};

export const getCarouselObserver = (
  carouselAnchor: HTMLElement,
  eventName: string
) => {
  const carouselObserver = new window.IntersectionObserver(
    (entries) =>
      entries.forEach((entry) => {
        if (entry.isIntersecting === true) {
          setTimeout(() =>
            carouselView(carouselAnchor, carouselObserver, eventName)
          );
        }
      }),
    { threshold: [0.8] }
  );

  return carouselObserver;
};

const setCarouselView = (carouselAnchor: HTMLElement, eventName: string) => {
  const carouselObserver = getCarouselObserver(carouselAnchor, eventName);

  window.addEventListener("load", () => {
    carouselObserver.observe(carouselAnchor);
  });
};

const trackProductClick = async (itemNumber: string) => {
  const products = await createProducts([{ itemNumber }]);
  if (products.length) {
    const formattedEcommerceProduct = formatEcommerceData(products);
    window.dataLayer.push({
      event: "productClick",
      ecommerce: formattedEcommerceProduct,
    });
  }
};

const setCarouselInteraction = (itemNumber: string, carouselId: string) => {
  const lookup = getCarouselInteraction();
  lookup[itemNumber.trim().toUpperCase()] = carouselId;
  session.setItem("carousel", JSON.stringify(lookup));
};

export const bindRecAiClickEvent = (id: string) => {
  const recAiCarousel = document.querySelector(id);
  if (recAiCarousel instanceof HTMLElement) {
    // This is the data-placement-id attribute on the recAi carousel
    const { placementId } = recAiCarousel.dataset;
    recAiCarousel
      .querySelectorAll("a.image,a.description,input[data-action=addToCart]")
      .forEach((link) => {
        link.addEventListener("click", ({ currentTarget: productLink }) => {
          if (!(productLink instanceof HTMLElement)) {
            throw new Error("product link must be instance of HTMLElement");
          }

          const parentItem = productLink.closest(".ag-item");
          if (!(parentItem instanceof HTMLElement)) {
            throw new Error("parent item must be instance of HTMLElement");
          }

          const { itemNumber } = parentItem.dataset;
          if ((placementId || id) && itemNumber) {
            setCarouselInteraction(
              itemNumber,
              placementId || id.replace("#", "")
            );
            if (productLink.nodeName === "A") {
              trackProductClick(itemNumber);
            }
          }
        });
      });
  }
};

export const bindYmanClickEvent = () => {
  const ymanCarousel = document.querySelector("div[data-feature-name=YMAN]");
  if (ymanCarousel instanceof HTMLElement) {
    ymanCarousel
      .querySelectorAll("a.image,a.description,input[data-action=addToCart]")
      .forEach((link) => {
        link.addEventListener("click", ({ currentTarget: productLink }) => {
          if (!(productLink instanceof HTMLElement)) {
            throw new Error("product link must be instance of HTMLElement");
          }

          const parentItem = productLink.closest(".ag-item");
          if (!(parentItem instanceof HTMLElement)) {
            throw new Error("parent item must be instance of HTMLElement");
          }

          const { itemNumber } = parentItem.dataset;
          if (itemNumber) {
            setCarouselInteraction(itemNumber, "yman");
            trackProductClick(itemNumber);
          }
        });
      });
  }
};

export const trackYmanCarouselViews = async () => {
  const carouselAnchor = document.querySelector("div[data-feature-name=YMAN]");
  if (carouselAnchor instanceof HTMLElement) {
    setCarouselView(carouselAnchor, "ymanEvent");
  }
};

export const trackRPFYCarouselViews = async () => {
  const observerAnchor = document.querySelector(
    "div[data-hypernova-key=RecommendedProductsForYou]"
  );
  if (observerAnchor instanceof HTMLElement) {
    setCarouselView(observerAnchor, "recAiEvent");
    const { getRecommendedProductsForYouCarousel } = await import(
      "./home/Carousel/Container"
    );

    await getRecommendedProductsForYouCarousel;

    const carouselAnchor = document.querySelector("#recommend-for-you");
    if (carouselAnchor instanceof HTMLElement) {
      sendGtmEvent({
        event: "recAiEvent",
        eventLabel: carouselAnchor.id,
        eventAction: "AI Impression",
      });
    }
  }
};

export const bindHomepageRecAiClickEvent = () => {
  const carouselAnchor = document.querySelector("#recommend-for-you");
  if (carouselAnchor instanceof HTMLElement) {
    carouselAnchor.querySelectorAll("a.image,a.description").forEach((link) => {
      link.addEventListener("click", ({ currentTarget: productLink }) => {
        if (!(productLink instanceof HTMLElement)) {
          throw new Error("product link must be instance of HTMLElement");
        }

        const parentItem = productLink.closest(".ag-item");
        if (!(parentItem instanceof HTMLElement)) {
          throw new Error("parent item must be instance of HTMLElement");
        }

        const { itemNumber } = parentItem.dataset;
        if (itemNumber) {
          setCarouselInteraction(itemNumber, carouselAnchor.id);
          if (productLink.nodeName === "A") {
            trackProductClick(itemNumber);
          }
        }
      });
    });
  }
};

export const sendATCWarrantyViewEvent = () => {
  sendGtmEvent({
    event: "WarrantyItemAddToCart",
  });
};

export const getIsInternalUserStatus = () => {
  const { user } = Store.getState();
  return user.analyticsData.is_internal === "YES";
};

export const getRandomNumberString = () => {
  return `${Date.now()}${Math.round(Math.random() * 100000)}`;
};

export const sendMiniAdsCarouselImpressionEvent = () => {
  sendGtmEvent({
    event: "MiniHomepageAd Impression",
    eventCategory: "MiniHomepageAd",
    eventAction: "All Impressions",
  });
};

export const bindMiniAdsCarouselClickEvent = () => {
  const miniAdsCarousel = document.querySelector(".subfeature-grid");
  if (miniAdsCarousel instanceof HTMLElement) {
    const miniAdLinks = miniAdsCarousel.querySelectorAll("div>a");

    miniAdLinks.forEach(function (link) {
      link.addEventListener("click", function () {
        sendGtmEvent({
          event: "MiniHomepageAd Click",
          eventCategory: "MiniHomepageAd",
          eventAction: "Click",
        });
      });
    });
  }
};

export const bindMiniAdsCarouselActualImpressionEvent = () => {
  const miniAdsCarousel = document.querySelector(".subfeature-grid");

  const carouselObserver = new window.IntersectionObserver(
    (entries) =>
      entries.forEach((entry) => {
        if (entry.isIntersecting === true) {
          setTimeout(() => sendMiniAdsCarouselActualImpressionEvent());
          carouselObserver.disconnect();
        }
      }),
    { threshold: [0.8] }
  );

  if (miniAdsCarousel instanceof HTMLElement) {
    carouselObserver.observe(miniAdsCarousel);
  }
};

const sendMiniAdsCarouselActualImpressionEvent = () => {
  sendGtmEvent({
    event: "MiniHomepageAd Actual Impression",
    eventCategory: "MiniHomepageAd",
    eventAction: "Carousel Impression",
  });
};

export const bindMiniAdsCarouselTracking = () => {
  sendMiniAdsCarouselImpressionEvent();
  bindMiniAdsCarouselClickEvent();
  bindMiniAdsCarouselActualImpressionEvent();
};

export const getAbTestResult = async (featureName: string) => {
  const endpointUrl = `/api:edgecache/getabtestresult/?abfeaturename=${featureName}`;

  return fetch(endpointUrl).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return null;
  });
};

export const getHashedCustomerEmail = (email: string) => {
  if (email) {
    return `${SHA256(email.trim().toLowerCase())}`;
  }
  return "";
};

export const sendHashedCustomerEmailSignupEvent = (email: string) => {
  const hashedEmail = getHashedCustomerEmail(email);
  sendGtmEvent({
    event: "setHashedUserEmailSha256",
    hashedEmail,
  });
};

export const addToWishlistClick = async (itemNumber: string) => {
  const products = await createProducts([{ itemNumber }]);
  if (products.length) {
    const formattedEcommerceData = formatEcommerceData(products);
    sendGtmEvent({
      event: "add_to_wishlist",
      ecommerce: formattedEcommerceData,
    });
  }
};

export const updateUserWithNewCartTotal = async (
  isAddition: boolean,
  update: number
): Promise<Throwable<number>> => {
  const result = await getDynamicData;
  if (result.isError) return result;
  const { user } = Store.getState();
  const startingCartTotal = user?.analyticsData?.cart_total ?? 0;

  user.analyticsData.cart_total = isAddition
    ? startingCartTotal + update
    : startingCartTotal - update;

  user.analyticsData.cart_total =
    Math.floor(user.analyticsData.cart_total * 100) / 100;

  if (user.analyticsData.cart_total < 0) {
    user.analyticsData.cart_total = 0;
  }
  Store.dispatch(updateUser(user));
  return { value: user.analyticsData.cart_total, isError: false };
};

const getAnalyticsDataFromStorage = () => {
  const storedData = session.getItem(analyticsStorageKey);
  if (storedData) {
    return JSON.parse(storedData);
  }
  return null;
};

const updateCustomDimensions = (analyticsData: AnalyticsData) => {
  const data = JSON.stringify(analyticsData);

  PushUserDataToDataLayer(analyticsData, true);
  session.setItem(analyticsStorageKey, data);
};

export const CheckForUserDataChangesAndUpdate = () => {
  const { analyticsData } = Store.getState().user;

  const data = getAnalyticsDataFromStorage();
  if (!data || !isEqual(data, analyticsData)) {
    updateCustomDimensions(analyticsData);
  } else {
    PushUserDataToDataLayer(analyticsData);
  }
};

const PushUserDataToDataLayer = (
  analyticsData: AnalyticsData,
  shouldFireCdsEvent = false
): void => {
  window.dataLayer.push(analyticsData);
  if (shouldFireCdsEvent) {
    sendGtmEvent({ event: "setCds" });
  }
};

export const updateVariationIds = async (
  abResponseOptions: UserAbFeatureOption[]
) => {
  const result = await getDynamicData;
  if (result.isError) return;

  const {
    user: {
      analyticsData: { variationIds },
    },
  } = Store.getState();

  const analyticsOptions = variationIds ? variationIds.split(",") : [];

  const newFeatureOptions = abResponseOptions
    .filter(
      (abResponseOption) =>
        !analyticsOptions?.find(
          (analyticsOption) =>
            analyticsOption.slice(0, analyticsOption.indexOf(":")) ===
            abResponseOption.featureName
        )
    )
    .map((x) => `${x.featureName}:${x.featureOption}`);

  if (newFeatureOptions.length) {
    const result = analyticsOptions?.concat(newFeatureOptions).join(",");

    sendGtmEvent({ event: "updateVariationId", updatedVariationId: result });
  }
};

export const pushAnalyticsDataToDataLayer = async () => {
  const result = await getDynamicData;
  if (result.isError) return;
  const { analyticsData } = Store.getState().user;
  window.dataLayer.push(analyticsData);
};

/**
 * This sends impression events to GTM and should only be used to batch and send ~10 events maximum per call to limit GTM request sizes.
 * GTM will reject requests that are too large.
 * @param itemNumbers
 */
const sendProductImpressionEvent = async (itemNumbers: Item[]) => {
  const products = await createProducts(itemNumbers);
  if (products.length) {
    const formattedEcommerceProducts = formatEcommerceData(products, {
      item_list_name: "Product Impression",
    });
    clearEcommerceInDataLayer();
    sendGtmEvent({
      event: "view_item_list",
      ecommerce: formattedEcommerceProducts,
    });
  }
};

export const bindTrackingForElements = (
  querySelectorForElementsToTrack: string,
  callback: IntersectionObserverCallback,
  percentageOfElementToTrack: number
) => {
  const elementsToTrack = document.querySelectorAll(
    querySelectorForElementsToTrack
  );

  if (elementsToTrack !== null) {
    const promoObserver = new window.IntersectionObserver(callback, {
      threshold: [percentageOfElementToTrack / 100],
    });

    Array.from(elementsToTrack).forEach((element) =>
      promoObserver.observe(element)
    );
  }
};

export const trackCarouselImpression = async (
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver
) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const carouselName = entry.target.getAttribute("gtm-carousel-name");
      const carouselModel = entry.target.getAttribute("gtm-carousel-model");

      if (carouselName) {
        pushCarouselEventToDataLayer(carouselName, carouselModel);
        observer?.unobserve(entry.target);
      }
    }
  });
};

export const getDataForProductsInRow = async (
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver
) => {
  const itemNumbers: Item[] = [];

  entries.forEach(async (entry) => {
    if (entry.isIntersecting === true) {
      const itemNumber = entry.target.getAttribute("gtm-tracking-number");

      if (itemNumber) {
        itemNumbers.push({ itemNumber });
      }

      observer?.unobserve(entry.target);
    }
  });

  if (itemNumbers.length > 0) {
    await sendProductImpressionEvent(itemNumbers);
  }
};

export const markProductViewed = async (itemNumber: string) => {
  await sendProductImpressionEvent([{ itemNumber }]);
};

export const checkIfOrderNumberWasPreviouslyTrackedInGA = (
  orderNumber: string
): boolean => {
  const previouslyStoredOrderNumbers =
    window.localStorage.getItem("orderNumbers")?.split(",") ?? [];

  if (previouslyStoredOrderNumbers.includes(orderNumber)) {
    return true;
  }

  storeOrderNumbers(previouslyStoredOrderNumbers, orderNumber);
  return false;
};

const storeOrderNumbers = (
  previouslyStoredOrderNumbers: string[],
  mostRecentOrderNumber: string
): void => {
  previouslyStoredOrderNumbers.push(mostRecentOrderNumber);
  let orderNumbersToStore = previouslyStoredOrderNumbers;

  const maxAmountOfOrderNumbersToStore = 15;

  if (previouslyStoredOrderNumbers.length > maxAmountOfOrderNumbersToStore) {
    orderNumbersToStore = [mostRecentOrderNumber];
  }

  window.localStorage.setItem("orderNumbers", orderNumbersToStore.toString());
};

const pushCarouselEventToDataLayer = (
  carouselName: string,
  carouselModel?: string | null
) => {
  sendGtmEvent({
    event: "carouselImpression",
    eventLabel: "",
    carouselName,
    carouselModel: carouselModel ?? checkIfCarouselIsRecAi(carouselName),
  });
};

export const checkIfCarouselIsRecAi = (carouselName: string) => {
  const RecAiCarouselNames = [
    "Recommended For You",
    "Best Selling Products",
    "Recommended Products",
    "Items You Might Like",
  ];

  if (RecAiCarouselNames.includes(carouselName)) {
    return "RecAi";
  }
  return "Manual";
};

export const getSelectedOptionAndSendGtmEvent = (
  selectedValue: string | number,
  eventName: string,
  rootNode: HTMLElement | Document = document
) => {
  const selectedText =
    rootNode.querySelector(
      `[value='${selectedValue.toString().replaceAll("'", "\\'")}']`
    )?.textContent ?? "";
  clearGa4EventInDataLayer();
  sendGtmEvent({
    event: "ga4_event",
    eventName,
    eventAction: eventName,
    eventLabel: selectedText,
  });
};
